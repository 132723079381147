import $ from 'jquery';
import './login.scss';

import sentryInitStandalone from '../sentry/init-standalone';

sentryInitStandalone();

$(() => {
  const $resetPasswordLink = $('.resetPasswordLink');
  const resetPasswordUrl = $resetPasswordLink.prop('href');

  $('input[name=login]').on('change', (event) => {
    const input = event.target;
    const email = input.value;
    const newUrl = `${resetPasswordUrl}?email=${encodeURIComponent(email)}`;
    $resetPasswordLink.prop('href', newUrl);
  });
});
