// eslint-disable-next-line no-underscore-dangle
const release = window._release_info;
// eslint-disable-next-line no-underscore-dangle
const appUser = window._user_info;

const enabled = Boolean(release.sentry_enabled);

let user = null;
if (enabled && appUser) {
  user = {
    id: appUser.id,
    username: appUser.email,
    email: appUser.email,
    name: appUser.name,
  };
}

export default {
  // This DSN is for a different project than the backend one.
  dsn: enabled ? 'https://f40890a73e374017bf736fcb150ac76f@o920046.ingest.sentry.io/6162280' : null,
  release: release.name,
  environment: release.environment,
  tracesSampleRate: 0.0,
  initialScope: { user },
};
